html {
    touch-action: manipulation;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input[type=date]::-webkit-calendar-picker-indicator {
    margin-left: -10px;
}

input[type=time]::-webkit-calendar-picker-indicator {
    margin-left: 0;
}


input {
    border-radius: 0;
}

input[type="search"] {
    -webkit-appearance: none;
}

.pointer-events-all {
    pointer-events: all;
}

#bankart_payment input[type='number']::-webkit-inner-spin-button,input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}